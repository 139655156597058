import { encode } from 'packages/encode-base64';
import { Link } from 'packages/links';

export interface AuthorizeLoginHashData {
  access_token: string;
  redirect_to?: string; // route not including gateway base-url
}

// Does not contain the hash segment as a parameter because of link-matcher and/or gateway limitations
export const createLoginAuthorizeLink = (root: Link) =>
  root.extend`/authorize`();

export const createLoginAuthorizeUrl = (
  root: Link,
  payload: AuthorizeLoginHashData
) => {
  const hash = encode(payload);
  return `${createLoginAuthorizeLink(root).url()}#${hash}`;
};
